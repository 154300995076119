import React from 'react';
import './impressum.scss';
const Impressum = () => {
  return (
    <div className="impressum">
      <h1>Impressum</h1>
      <p>Angaben gemäß § 5 DDG</p>
      <p>
        Bilal Yer <br />
        Nagolder Straße 4 <br />
        71131 Jettingen <br />
      </p>
      <p>
        <strong>Vertreten durch:</strong> <br />
        Bilal Yer
      </p>
      <p>
        <strong>Registereintrag:</strong> <br />
        Eintragung im Handelsregister. <br />
        Registergericht: Stuttgart <br />
        Registernummer: HRB 763963
      </p>
      <p>
        <strong>Umsatzsteuer-ID:</strong> <br />
        Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: 56096/16047
      </p>
      <p>
        <strong>Haftungsausschluss:</strong>
        <br />
        <strong>Haftung für Inhalte:</strong> <br />
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
        Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen...
        {/* Der Text hier kann weiter fortgesetzt werden */}
      </p>
    </div>
  );
};

export default Impressum;
